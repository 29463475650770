<template>
  <div class="s-logos-container">
    <PageFooterPartnersLogos />
    <PageFooterShopsCategory />
  </div>
</template>

<script>
  export default {}
</script>

<style scoped lang="postcss">
  .s-logos-container {
    width: 100%;
    display: flex;
    @apply flex-col lg:flex-row;
  }
</style>
